/**
 * Login Page
 */

// Core imports
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Style, SEO and settings
import Helmet from 'react-helmet';
import { Col, Grid, Row, styled, withTheme } from '@smooth-ui/core-sc';

// Additional Components/Containers
import Button from '_platform/src/components/Button/Button';
import LoadAsync from '_platform/src/utils/LoadAsync';
import PublicComponent from '_platform/src/utils/PublicComponent';
import getReturnUrlPath from '_platform/src/utils/getReturnUrlPath';

import { withSettings } from 'containers/WebApp/SettingsContext';
import bgImage1x from './images/home-page-bg@1x.jpg';
import bgImage2x from './images/home-page-bg@2x.jpg';
import logo from '../../theme/logo-header-@2x.png';

import footer2x from './images/logo-footer-white-@2x.png';

const LoginProvider = LoadAsync(() =>
  import(
    /* webpackChunkName: "loginProvider" */ '_platform/src/containers/LoginProvider/LoginProvider'
  )
);
const LoginForm = LoadAsync(() =>
  import(
    /* webpackChunkName: "loginForm" */ '_platform/src/components/LoginForm/LoginForm'
  )
);

const PageContainer = styled.div`
  padding-top: 20px;
  padding-bottom: 80px;
  position: relative;
  align-items: center;
  background-color: #dfe9e6;
  background-image: url(${bgImage1x});
  background-position: center top;
  background-repeat: none;
  background-size: cover;
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  justify-content: center;
  @media only screen and (min-device-pixel-ratio: 1.5),
    only screen and (min-resolution: 144dpi),
    only screen and (min-resolution: 1.5dppx) {
    background-color: #dfe9e6;
    background-image: url(${bgImage2x});
  }

  .footer {
    position: absolute;
    bottom: 0;
    min-height: 66px;
  }
`;

const LoginLogo = styled.h1`
  margin-bottom: 0;
  margin-top: 0;
  padding-bottom: 1rem;
  padding-top: 0;
  img {
    height: auto;
    width: 222px;
  }
`;

const IntroContainer = styled(Grid)`
  box-sizing: border-box;

  h2 {
    font-size: 30px;
  }
  p {
    color: #000;
  }
  a {
    color: #fff;
    &:visited {
      color: #fff;
    }
  }

  .register-button {
    background-color: #01916d;
    color: #fff;
    text-transform: uppercase;
    width: 200px;
  }

  .login-container {
    background-color: #01916d;
    color: #fff;
    padding: 2rem;
  }

  .register-container {
    padding: 2rem;
    background-color: #fff;
  }
`;

const LoginContainer = styled.div`
  background-color: #01916d;
  color: #fff;

  p {
    color: #fff;
  }

  button {
    color: #01916d;
    background-color: #fff;
    text-transform: uppercase;
  }

  .reset-password {
    a {
      font-size: 14px;
      font-weight: normal;
    }
  }
`;

const LoginPage = ({ settings }) => (
  <PublicComponent
    redirectOnError={
      // Redirect to returnUrl, if it's not the same as loginPagePath
      (getReturnUrlPath !== settings.settingsApp.loginPagePath &&
        getReturnUrlPath) ||
      '/'
    }
  >
    <Helmet>
      <title>Login</title>
      {/*<meta name="description" content="Page description" />*/}
    </Helmet>

    <PageContainer>
      <Grid className="page-container">
        <IntroContainer>
          <Row justifyContent="center">
            <Col xs={12} lg={8} className="text--center register-container">
              <LoginLogo className="text--center">
                <img
                  src={logo}
                  alt={
                    settings.settingsApp && settings.settingsApp.siteName
                      ? settings.settingsApp.siteName
                      : 'Fujifilm X Rewards'
                  }
                />
              </LoginLogo>
              <p className="text--uppercase">Welcome to FUJIFILM X Rewards</p>
              <p>
                The Fujifilm X Rewards program is designed to reward you for
                your continuous learning and sales of Fujifilm X Series, GFX
                System, and XF, XC and GF products. The more products you sell
                and training modules you complete, the more points you earn and
                the greater the rewards.
              </p>
              <p>
                If you have already received a welcome email, please login using
                the username and password provided. If you have not received a
                welcome email, click on the REGISTER HERE button to register to
                participate in Fujifilm X Rewards.
              </p>
              <Button as={Link} className="register-button" to="/register/">
                Register Here
              </Button>
            </Col>
            <Col xs={12} lg={4} className="login-container">
              <LoginContainer>
                <h5 className="text--uppercase">
                  Already registered? Login below
                </h5>
                <LoginProvider disableMeta>
                  <LoginForm formLabels={{ labelUserName: 'Username' }} />
                </LoginProvider>
              </LoginContainer>
            </Col>
          </Row>
        </IntroContainer>
      </Grid>
      <Grid>
        <Row justifyContent="center">
          <Col xs={12} className="footer text--right">
            <img src={footer2x} alt="Fujifilm" height="57px" />
          </Col>
        </Row>
      </Grid>
    </PageContainer>
  </PublicComponent>
);

LoginPage.propTypes = {
  settings: PropTypes.object.isRequired,
};

export default withTheme(withSettings(LoginPage));
